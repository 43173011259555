import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from './Header'
import { Footer } from './Footer'

export const Layouts = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />

        </>
    )
}
